import { AnonymousCredential, BlobServiceClient } from '@azure/storage-blob';

export const blobContainerClient = (containerName = process.env.APP_STORAGE_CONTAINER_STARTUPS) => {
  const anonymousCredential = new AnonymousCredential();
  const blobServiceClient = new BlobServiceClient(
    `${process.env.APP_STORAGE_URI}${process.env.APP_STORAGE_SAS}`,
    anonymousCredential
  );
  return blobServiceClient.getContainerClient(containerName);
};
