import es from '@vueform/vueform/locales/es';
import tailwind from '@vueform/vueform/dist/tailwind';
// eslint-disable-next-line import/named
import { defineConfig } from '@vueform/vueform';
import { uploadBlob, deleteBlob, uidBlobName } from '@/services/storage/blobService';

export default defineConfig({
  theme: tailwind,
  locales: { es },
  locale: process.env.APP_LOCALE || 'es',
  endpoints: {
    uploadTempFile: (file, el$) =>
      new Promise(resolve => {
        const uidName = uidBlobName(file.name);
        const progressCallback = e => {
          const percentage = Math.round((e.loadedBytes / file.size) * 100);
          el$.progress = percentage;
          if (percentage === 100) {
            const response = {
              tmp: uidName,
              url: `${process.env.APP_STORAGE_URI}${process.env.APP_STORAGE_CONTAINER_STARTUPS}/${uidName}`,
              originalName: file.name
            };
            resolve(response);
          }
        };
        uploadBlob(file, uidName, file.type, progressCallback);
      }),
    removeTempFile: ({ tmp }) => {
      deleteBlob(tmp);
    },
    removeFile: ({ tmp }) => {
      deleteBlob(tmp);
    }
  }
});
