import { blobContainerClient } from './blobServiceClient';

const MAX_SINGLE_SHOT_SIZE = 4 * 1024 * 1024; // 4MB

export const uidBlobName = blobName => `${new Date().getTime()}${blobName}`;

export const uploadBlob = async (blob, blobName, contentType, onProgress, containerName) => {
  const blobClient = blobContainerClient(containerName).getBlobClient(blobName);
  const blockBlobClient = blobClient.getBlockBlobClient();
  const uploadBlobResponse = await blockBlobClient.upload(blob, blob.size, {
    blobHTTPHeaders: { blobContentType: contentType },
    maxSingleShotSize: MAX_SINGLE_SHOT_SIZE,
    onProgress
  });
  return uploadBlobResponse;
};

export const deleteBlob = async (blobName, containerName) =>
  await blobContainerClient(containerName).deleteBlob(blobName);
